<template>

</template>
<script>
export default {
  props: {},
  data: (vm) => ({
    filterItem: {},
  }),
  computed: {
    nameField() {
      if (this.$i18n.locale === "zhHant") {
        return "name_tc";
      } else {
        return "name_sc";
      }
    },
    nameFieldFilter() {
      return this.nameField + "__startswith";
    },
  },
  methods: {
    onChange() {
      console.log("this.filterItem", this.filterItem);
      if (this.$i18n.locale === "zhHans") {
        for (const [key, value] of Object.entries(this.filterItem)) {
          if (key.search('name_tc')>=0) {
            var new_key = key.replace('name_tc','name_sc')
            delete this.filterItem[key]
            this.filterItem[new_key] = value
          }
        }
        console.log('sc filter item',this.filterItem)
      }
      this.$emit("onFilterChange", this.filterItem);
    },
  },
};
</script>