<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.name_tc"
                :label="$t('name_tc')"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('name_tc') }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.deliver_unit_amount_fee"
                :label="$t('deliver-fee.pieces')"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="item.deliver_unit_weight_fee"
                :label="$t('deliver-fee.weight')"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="item.deliver_unit_cubicfeet_fee"
                :label="$t('deliver-fee.cubic-feet')"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="item.deliver_unit_package_fee"
                :label="$t('deliver-fee.package')"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="item.additional_fees"
                :items="additional_fees"
                :item-text="item=>item.name+' '+$t(getText(item.unit,deliver_fee_unit_items))+' $'+item.unit_fee"
                item-value="id"
                :label="$t('commodity.additional-fee')"
                multiple
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
import { action_items, deliver_fee_unit_items } from "@/definition.js";
import CommonUtil from "@/utils/commonutil.js";
export default {
  props: {
    additional_fees:[],
  },
  data: (vm) => ({
      topic: vm.$i18n.t("commodity.deliver-suject"),
      action_items: action_items,
      deliver_fee_unit_items: deliver_fee_unit_items,
  }),
  computed: {
    allowSave() {
      return false;
    },
  },
  mixins: [BaseDialog],
  methods: {
    getText: CommonUtil.getText,
  },
}
</script>