<template>
  <v-container fluid>
    <v-col class="text-right">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="multiUpdateAdditionalFees"
      >
        <v-icon>mdi-pencil</v-icon>
        {{ $t("commodity.multi-update-additional-fees") }}
      </v-btn>
      <v-btn
        color="primary"
        dark
        class="mb-2  ml-4"
        @click="createItem"
      >
        <v-icon>mdi-plus</v-icon>
        {{ $t("create") }}
      </v-btn>
    </v-col>
    <DeliverSujectDialog
      :show="showDialog"
      :action="action"
      :item="dialogItem"
      :additional_fees="additional_fees"
      v-on:onClose="onCloseEvent"
      v-on:onSave="onSaveEvent"
    ></DeliverSujectDialog>
    <DeliverSujectMultiUpdateDialog
      :show="showDialog2"
      :action="dialogAction2"
      :item="dialogItem2"
      :additional_fees="additional_fees"
      v-on:onClose="onCloseEvent2"
      v-on:onSave="onSaveEvent2"
    ></DeliverSujectMultiUpdateDialog>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('commodity.deliver-type-list')"
      class="px-5 py-3"
    >
      <v-data-table
        v-model="selected"
        :headers="showHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
        show-select
      >
        <template v-slot:header>
          <DeliverSujectFilter
            v-on:onFilterChange="onFilterChange"
          ></DeliverSujectFilter>
        </template>
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:item.name="{ item }">
          {{ get_deliver_suject_name(item) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="editItem(item)"
              >mdi-pencil</v-icon>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import BaseTable from "./BaseTable.vue";
import DeliverSujectDialog from "../dialogs/DeliverSujectDialog";
import DeliverSujectMultiUpdateDialog from "../dialogs/DeliverSujectMultiUpdateDialog";
import DeliverSujectFilter from "../filters/DeliverSujectFilter";
import { action_items } from "@/definition.js";
export default {
  data: (vm) => ({
    url: "/commodity/deliver-suject/",
    headersMap: {
      id:{
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
      },
      name_tc:{
        sortable: true,
        text: vm.$i18n.t("name"),
        value: "name_tc",
      },
      name_sc:{
        sortable: true,
        text: vm.$i18n.t("name"),
        value: "name_sc",
      },
      amount_fee:{
        sortable: true,
        text: vm.$i18n.t("deliver-fee.pieces"),
        value: "deliver_unit_amount_fee",
      },
      weight_fee:{
        sortable: true,
        text: vm.$i18n.t("deliver-fee.weight"),
        value: "deliver_unit_weight_fee",
      },
      cubicfeet_fee:{
        sortable: true,
        text: vm.$i18n.t("deliver-fee.cubic-feet"),
        value: "deliver_unit_cubicfeet_fee",
      },
      package_fee:{
        sortable: true,
        text: vm.$i18n.t("deliver-fee.package"),
        value: "deliver_unit_package_fee",
      },
      actions:{
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
    },
    tcHeaders: ['id', 'name_tc','amount_fee','weight_fee','cubicfeet_fee','package_fee','actions'],
    scHeaders: ['id', 'name_sc','amount_fee','weight_fee','cubicfeet_fee','package_fee','actions'],
    action_items: action_items,
    additional_fees: [],
    selected: [],
    dialogAction2: null,
    dialogItem2: {},
    showDialog2: false,
  }),
  mixins: [BaseTable],
  components: {
    DeliverSujectDialog,
    DeliverSujectMultiUpdateDialog,
    DeliverSujectFilter,
  },
  methods: {
    get_deliver_suject_name(item){
      return this.getHanText(item,"name")
    },
    createItem(item) {
      this.dialogItem = this._.cloneDeep(this.defaultItem);
      this.action = this.action_items.create;
      this.showDialog = true;
    },
    editItem(item) {
      this.orig_dialogItem = this._.cloneDeep(item);
      this.dialogItem = this._.cloneDeep(item);
      if (item.extra) {
        let extra = JSON.parse(item.extra)
        if (Object.keys(extra).includes('additional_fees')){
          this.dialogItem.additional_fees = extra['additional_fees']
        }
      }
      this.action = action_items.edit;
      this.showDialog = true;
    },
    getAdditionalFees(){
      this.getApi("/commodity/additional-fee/",this.setAdditionalFees)
    },
    setAdditionalFees(datas){
      this.additional_fees = datas.results
    },
    multiUpdateAdditionalFees(){
      if (this.selected.length > 0) {
        this.dialogItem2 = {
          'selected': this.selected,
        }
        this.showDialog2 = true
      } else {
        alert(this.$i18n.t("alert.selected-empty"))
      }
    },
    onSaveEvent2(item){
      this.showDialog2 = false
      this.selected = []
      let post_item = {}
      post_item['additional_fees'] = item['additional_fees']
      let deliver_sujects = []
      for (const [key, data] of Object.entries(item['selected'])) {
        deliver_sujects.push(data.id);
      }
      post_item['deliver_sujects'] = deliver_sujects
      // console.log(post_item)
      this.postApi(post_item, this.url+"multiUpdateAdditionalFee/");
    },
    onCloseEvent2(){
      this.showDialog2 = false;
    },
  },
  watch: {
    options: {
      handler(){
        this.getAdditionalFees()
      }
    },
  },
};
</script>