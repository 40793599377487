var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.multiUpdateAdditionalFees}},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.$t("commodity.multi-update-additional-fees"))+" ")],1),_c('v-btn',{staticClass:"mb-2  ml-4",attrs:{"color":"primary","dark":""},on:{"click":_vm.createItem}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("create"))+" ")],1)],1),_c('DeliverSujectDialog',{attrs:{"show":_vm.showDialog,"action":_vm.action,"item":_vm.dialogItem,"additional_fees":_vm.additional_fees},on:{"onClose":_vm.onCloseEvent,"onSave":_vm.onSaveEvent}}),_c('DeliverSujectMultiUpdateDialog',{attrs:{"show":_vm.showDialog2,"action":_vm.dialogAction2,"item":_vm.dialogItem2,"additional_fees":_vm.additional_fees},on:{"onClose":_vm.onCloseEvent2,"onSave":_vm.onSaveEvent2}}),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('commodity.deliver-type-list')}},[_c('v-data-table',{attrs:{"headers":_vm.showHeaders,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"multi-sort":"","show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('DeliverSujectFilter',{on:{"onFilterChange":_vm.onFilterChange}})]},proxy:true},{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":true,"show-current-page":true,"first-icon":"mdi-chevron-double-left","last-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","items-per-page-options":[20, 50, 100]},on:{"update:options":updateOptions}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_deliver_suject_name(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }