<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              {{ selectedTitles }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="item.additional_fees"
                :items="additional_fees"
                :item-text="item=>item.name+' $'+item.unit_fee"
                item-value="id"
                :label="$t('commodity.additional-fee')"
                multiple
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
export default {
  props: {
    additional_fees:[],
  },
  data: (vm) => ({
      topic: vm.$i18n.t("commodity.deliver-type"),
  }),
  computed: {
    allowSave() {
      return false;
    },
    selectedTitles() {
      let titles = []
      for (const [key, data] of Object.entries(this.item['selected'])) {
        titles.push(data.name_tc);
      }
      return titles.join(', ')
    },
  },
  mixins: [BaseDialog],
}
</script>